






































































































































import { Component, Vue } from "vue-property-decorator";
import Input from "@/components/form/Input.vue";
import { validationMixin } from "vuelidate";
import { email, minLength, requiredIf, sameAs } from "vuelidate/lib/validators";

@Component({
  components: { Input },
  mixins: [validationMixin],
  validations: {
    name: {
      required: requiredIf((vm) => !vm.isRegisteringWithGoogle),
    },
    email: {
      email,
      required: requiredIf((vm) => !vm.isRegisteringWithGoogle),
    },
    password: {
      required: requiredIf((vm) => !vm.isRegisteringWithGoogle),
      minLength: minLength(6),
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
})
export default class Register extends Vue {
  private name = "";
  private email = "";
  private password = "";
  private repeatPassword = "";
  private showPassword = false;
  private showRepeatPassword = false;

  async submitHandler(): Promise<void> {
    if (!this.$v.$invalid) {
      const [firstName, lastName] = this.name.split(" ");
      const userObject = {
        firstName,
        lastName,
        email: this.email,
        password: this.password,
      };
      const isSuccessful = await this.$store.dispatch(
        "user/register",
        userObject
      );

      if (isSuccessful) {
        this.resetForm();
        await this.$router.push({ name: "Login" });
      }
    }
  }

  resetForm(): void {
    this.name = "";
    this.email = "";
    this.password = "";
    this.repeatPassword = "";
    this.$nextTick(() => {
      this.$v.$reset();
    });
  }
}
